import gql from 'graphql-tag'


const listResponse = `
  id type code name isActive
`

export const LIST_DEVICE = (templateType) => gql`query LIST_DEVICE ($deviceType: String!, $q: FilterInput) {
  devices: list${templateType} (deviceType: $deviceType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type code name isActive
  email clientId clientSecret
`

export const DETAIL_DEVICE = (templateType) => gql`query DETAIL_DEVICE ($deviceType: String!, $deviceId: Int!) {
  device: detail${templateType} (deviceType: $deviceType, deviceId: $deviceId) {${detailResponse}}
}`

export const CREATE_DEVICE = (templateType) => gql`mutation CREATE_DEVICE ($deviceType: String!, $input: ${templateType}Input!) {
  createDevice: create${templateType} (deviceType: $deviceType, input: $input) {${detailResponse}}
}`

export const UPDATE_DEVICE = (templateType) => gql`mutation UPDATE_DEVICE ($deviceType: String!, $deviceId: Int!, $input: ${templateType}Input!) {
  updateDevice: update${templateType} (deviceType: $deviceType, deviceId: $deviceId, input: $input) {${detailResponse}}
}`

export const DESTROY_DEVICE = (templateType) => gql`mutation DESTROY_DEVICE ($deviceType: String!, $deviceId: Int!) {
  destroyDevice: destroy${templateType} (deviceType: $deviceType, deviceId: $deviceId) {id}
}`

export const VERIFY_TOKEN = (templateType) => gql`mutation VERIFY_TOKEN ($deviceType: String!, $deviceId: Int!, $redirectUrl: String!) {
  result: verify${templateType}Token (deviceType: $deviceType, deviceId: $deviceId, redirectUrl: $redirectUrl)
}`

export const SAVE_TOKEN = (templateType) => gql`mutation SAVE_TOKEN ($deviceType: String!, $deviceId: Int!, $code: String!, $redirectUrl: String!) {
  result: save${templateType}Token (deviceType: $deviceType, deviceId: $deviceId, code: $code, redirectUrl: $redirectUrl)
}`

export const SEND_MAIL = (templateType) => gql`mutation SEND_MAIL ($deviceType: String!, $deviceId: Int!, $input: MailOptionsInput!) {
  result: send${templateType}Mail (deviceType: $deviceType, deviceId: $deviceId input: $input)
}`
