<template>
  <button
    class="btn btn-info"
    @click="verifyToken">
    Verify Email
  </button>
</template>

<script>
import { VERIFY_TOKEN } from './graph'

export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    redirectUrl () {
      const arr = location.pathname.split('/')
      arr.pop()
      return location.protocol + '//' + location.host + arr.join('/')
    }
  },
  methods: {
    verifyToken () {
      this.$apollo.mutate({
        mutation: VERIFY_TOKEN(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          redirectUrl: this.redirectUrl
        }
      }).then((res) => {
        let link = document.createElement("a")
        link.href = res.data.result
        link.click()
      }).catch(err => {
        this.$toasted.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
